import { Input } from '@/components/components/ui/input';
import RocketSvg from './RocketSvg';
import Image from 'next/image';
import Billboard from './trustbar_images/billboard-logo.png';
import NSF from './trustbar_images/nsf-logo.png';
import USNews from './trustbar_images/us-news-world-logo.png';
import IES from './trustbar_images/ies-logo-2.png';
import { Button } from '@/components/HomePageV2/Components/Button';
import EmailSignupField from '@/components/HomePageV2/EmailSignupField/EmailSignupField';

export default function ChartSection() {
    return (
        <div className=' relative mt-[110px]  md:mb-[100px] px-0 lg:px-12 lx:px-[70px]'>
            {/* blur effect pink */}
            <div className='hidden md:block absolute top-[-100px] left-0 rounded-full opacity-[1.4] bg-[#B031FA] bg-opacity-[0.7] blur-[250px] w-[729px] h-[585px] z-[1]' />
            <h2 className='hidden lg:block my-0 text-center text-white text-[60px] font-medium leading-[60px] relative z-[2]'>
                We've cracked <span className='text-[#F11581] font-normal font-Gloria'>the code.</span> <br /> Our results
                <span className='text-[#14B5BD] font-normal font-Gloria'> are unmatched.</span>{' '}
            </h2>
            <div className='mx-auto max-w-[1300px] bg-white lg:rounded-[18px] pl-4 md:pl-8 lg:pl-[89px] pr-4 md:pr-8 lg:pr-[60px] py-[50px] mt-10 relative z-[2]'>
                <h2 className='block lg:hidden my-0 text-center text-[#0C1B32] text-[40px] sm:text-[50px] xl:text-[60px] font-medium leading-[2.8rem] relative z-[2] mb-14'>
                    We've cracked <br /> <span className='text-[#F11581] font-normal font-Gloria'>the code.</span> <br /> Our results <br />
                    <span className='text-[#14B5BD] font-normal font-Gloria'> are unmatched.</span>{' '}
                </h2>
                {/* top section - graph / bullet points */}
                <div className='flex flex-col md:flex-row items-center md:items-start gap-8 md:gap-12 mb-10 md:mb-[50px]'>
                    <div className='max-w-[384px] pt-[100px] w-full'>
                        <div className='relative flex items-end justify-center gap-8 '>
                            {/* Pre-test bar */}
                            <div className='flex flex-col items-center gap-2 '>
                                <div className='text-4xl font-semibold text-[#1C0057] ml-1.5'>40%</div>
                                <div className='w-[73px] h-[170px] bg-[#1C0057] flex items-end justify-center rounded-xl'>
                                    <span className='text-white font-medium text-xl rotate-[-90deg] mb-16 whitespace-nowrap'>PRE-TEST</span>
                                </div>
                            </div>

                            {/* Post-test bar with rocket */}
                            <div className='flex flex-col items-center gap-2'>
                                <div className='relative w-[73px] h-[300px] bg-gradient-to-b from-[#B031FA] to-[#7564B3] flex items-end justify-center rounded-xl z-5'>
                                    <span className='text-white text-xl font-medium rotate-[-90deg] mb-[75px] whitespace-nowrap'>POST-TEST</span>
                                    <div className='absolute inset-0 top-[-70px] left-[-145px]'>
                                        <div className='text-[48px] font-semibold text-[#1C0057]'>100%</div>
                                    </div>
                                    <div className='absolute inset-0 left-[-154px] top-[-20px] z-20'>
                                        <svg width='183' height='18' viewBox='0 0 183 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                            <path
                                                d='M164.697 8.84961C164.697 13.7097 168.637 17.6496 173.497 17.6496C178.357 17.6496 182.297 13.7097 182.297 8.84961C182.297 3.9895 178.357 0.0496092 173.497 0.0496092C168.637 0.0496092 164.697 3.9895 164.697 8.84961ZM0.796875 10.4996H173.497V7.19961H0.796875V10.4996Z'
                                                fill='#F11581'
                                            />
                                        </svg>
                                    </div>
                                    <div className='absolute inset-0 top-[-120px] left-[-46px] z-10'>
                                        <RocketSvg />
                                    </div>
                                </div>
                            </div>
                            {/* Pink line at 100% */}
                            <div className='absolute left-0 top-[2rem] w-full border-t-2 border-[#FF3D6E]' />
                        </div>
                        {/* divider line */}
                        <div
                            className='h-[2.2px] max-w-[360px] mx-auto my-7'
                            style={{
                                background:
                                    'linear-gradient(90deg, rgba(110, 96, 230, 0.00) 0%, rgba(110, 96, 230, 0.30) 50.16%, rgba(110, 96, 230, 0.00) 100%)'
                            }}
                        />
                        <div className='text-center'>
                            <h4 className='text-[#1C0057] text-3xl lg:text-4xl font-semibold mb-2 mt-0 hidden md:block'>Statewide Study</h4>
                            <p className='text-[#9D42E3] text-3xl md:text-[18px] lg:text-xl font-semibold md:font-normal my-0'>
                                Math Scores Soar in a Semester
                            </p>
                        </div>
                    </div>
                    <div className='md:space-y-16 w-full max-w-[768px] '>
                        {/* desktop */}

                        <h2 className='text-[#1C0057] text-xl lg:text-2xl font-normal lg:leading-[30px] hidden md:block max-w-[650px]'>
                            Our unique learning system has produced remarkable gains for thousands of students. Here are some highlights:
                        </h2>
                        <h2 className='text-[#1C0057] text-lg lg:text-2xl font-normal leading-[24px] block md:hidden text-center mt-[-10px]'>
                            Our unique learning system has produced remarkable gains for thousands of students. Here are some highlights:
                        </h2>

                        <div className='space-y-5 md:space-y-16  '>
                            {/* desktop */}
                            <div className='hidden md:flex items-start gap-4 '>
                                <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
                                    <ellipse cx='7.1804' cy='6.78711' rx='6.31712' ry='6.5' fill='url(#paint0_linear_1272_59710)' />
                                    <defs>
                                        <linearGradient
                                            id='paint0_linear_1272_59710'
                                            x1='6.92034'
                                            y1='2.6068'
                                            x2='7.86914'
                                            y2='17.0121'
                                            gradientUnits='userSpaceOnUse'
                                        >
                                            <stop stopColor='#6E60E6' />
                                            <stop offset='1' stopColor='#1CC8E1' />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div className='-mt-2'>
                                    <h3 className='text-[#1C0057] font-normal text-[18px] lg:text-[22px] my-0'>
                                        MASSIVE IMPACT: Math scores soar from <span className='font-semibold'>40% to 100%</span>
                                    </h3>
                                    <p className='text-[#1C0057] font-normal text-sm sm:text-base mt-1.5 my-0'>(Statewide study - 1,200 students)</p>
                                </div>
                            </div>
                            {/* mobile */}
                            <div className='block md:hidden text-center'>
                                <li className='text-[#1C0057] font-normal text-lg my-0'>Math scores soar from <span className='font-semibold'>40% to 100%</span></li>
                            </div>
                            {/* desktop */}
                            <div className='hidden md:flex gap-4'>
                                <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
                                    <ellipse cx='7.1804' cy='6.78711' rx='6.31712' ry='6.5' fill='url(#paint0_linear_1272_59710)' />
                                    <defs>
                                        <linearGradient
                                            id='paint0_linear_1272_59710'
                                            x1='6.92034'
                                            y1='2.6068'
                                            x2='7.86914'
                                            y2='17.0121'
                                            gradientUnits='userSpaceOnUse'
                                        >
                                            <stop stopColor='#6E60E6' />
                                            <stop offset='1' stopColor='#1CC8E1' />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div className='-mt-2'>
                                    <h3 className='text-[#1C0057] font-normal text-[18px] lg:text-[22px] my-0'>
                                        MAJOR GROWTH: Quiz grades improve from <span className='font-semibold'>D to B+</span>
                                    </h3>
                                    <p className='text-[#1C0057] font-normal text-sm sm:text-base mt-1.5 my-0'>(Districtwide study - 2,700 students)</p>
                                </div>
                            </div>
                            {/* mobile */}
                            <div className='block md:hidden text-center'>
                                <li className='text-[#1C0057] font-normal text-lg  my-0'>Quiz grades improve from <span className='font-semibold'>D to B+</span></li>
                            </div>
                            {/* desktop */}
                            <div className='hidden md:flex gap-4'>
                                <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
                                    <ellipse cx='7.1804' cy='6.78711' rx='6.31712' ry='6.5' fill='url(#paint0_linear_1272_59710)' />
                                    <defs>
                                        <linearGradient
                                            id='paint0_linear_1272_59710'
                                            x1='6.92034'
                                            y1='2.6068'
                                            x2='7.86914'
                                            y2='17.0121'
                                            gradientUnits='userSpaceOnUse'
                                        >
                                            <stop stopColor='#6E60E6' />
                                            <stop offset='1' stopColor='#1CC8E1' />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div className='-mt-2'>
                                    <h3 className='text-[#1C0057] font-normal text-[18px] lg:text-[22px] my-0'>
                                        IMMEDIATE RESULTS: Test scores <span className='font-semibold'>double in four weeks</span>
                                    </h3>
                                    <p className='text-[#1C0057] font-normal text-sm sm:text-base mt-1.5 my-0'>(Summer school study - 270 students)</p>
                                </div>
                            </div>
                            {/* mobile */}
                            <div className='block md:hidden text-center'>
                                <li className='text-[#1C0057] font-normal text-lg my-0'>Test scores <span className='font-semibold'>double in four weeks</span></li>
                            </div>
                        </div>
                    </div>
                </div>
                {/* divider line */}
                <div
                    className='h-[2.2px] max-w-[1216px] mx-auto '
                    style={{
                        background: 'linear-gradient(90deg, rgba(110, 96, 230, 0.00) 0%, rgba(110, 96, 230, 0.30) 50.16%, rgba(110, 96, 230, 0.00) 100%)'
                    }}
                />
                {/* trust bar */}
                <div className='container mx-auto px-2 sm:px-4 py-10 md:py-12'>
                    <div className='grid grid-cols-2 lg:grid-cols-4 gap-3 md:gap-8 items-center justify-items-center'>
                        <div className='w-full max-w-[160px] sm:max-w-[200px] h-auto relative'>
                            <Image src={Billboard} alt='Billboard' className='object-contain' />
                        </div>
                        <div className='w-full max-w-[150px] sm:max-w-[200px] h-auto relative'>
                            <Image src={USNews} alt='US News & World Report' className='object-contain' />
                        </div>
                        <div className='w-full max-w-[170px] sm:max-w-[200px] h-auto relative'>
                            <Image src={NSF} alt='National Science Foundation' className='object-contain' />
                        </div>
                        <div className='w-full max-w-[300px] h-[69px] relative flex items-center justify-center p-0 sm:p-5 lg:p-0 xl:p-5'>
                            <Image src={IES} alt='Institute of Education Sciences' className='object-contain ' />
                        </div>
                    </div>
                </div>

                {/* divider line */}
                <div
                    className='h-[2.2px] max-w-[1216px] mx-auto'
                    style={{
                        background: 'linear-gradient(90deg, rgba(110, 96, 230, 0.00) 0%, rgba(110, 96, 230, 0.30) 50.16%, rgba(110, 96, 230, 0.00) 100%)'
                    }}
                />

                {/* Email capture  */}
                <div>
                    <EmailSignupField
                        className='flex flex-col md:flex-row justify-center items-center gap-4 w-full mt-10'
                        inputClassName='text-[18px] w-full max-w-[260px] md:max-w-[300px] pl-0 md:pl-4 py-[26px] bg-gray-50  border-[1px] border-solid border-[#1C0057] text-[#1C0057] font-normal rounded-full text-center md:text-left'
                    />
                    <div className='max-w-full md:max-w-[465px] mx-auto'>
                        <p className='text-base text-[#1C0057] my-0 ml-0 mt-2 text-center md:text-left'>No credit card required.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
