import React from 'react';

const RedirectModal = ({ reActivate }) => {
    let headerCopy = 'Account Already Exists';
    let bodyCopy = 'This email is already associated with an account. Redirecting you to login...';
    if (reActivate) {
        headerCopy = 'GREAT NEWS!';
        bodyCopy = "We've activated a brand new 7-day FREE trial for you. Redirecting you to log in now...";
    }
    return (
        <div className='fixed inset-0 z-50 flex items-center justify-center'>
            <div className='fixed inset-0 bg-black/50 transition-all duration-500 ease-out animate-[fadeIn_500ms_ease-out]'></div>
            <div className='relative z-50 bg-white p-8 rounded-lg shadow-lg max-w-md w-full mx-4 transition-all duration-500 ease-out animate-[modalSlideIn_500ms_ease-out]'>
                <div className='flex flex-col items-center text-center'>
                    <h2 className='text-2xl font-bold mb-4 text-gray-800'>{headerCopy}</h2>
                    <p className='text-gray-600 mb-4'>{bodyCopy}</p>
                    <div className='animate-pulse mt-2'>
                        <div className='h-1 w-24 bg-blue-200 rounded'></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RedirectModal;
