import { createUrlWithParams } from '@/lib/urls';
import { beginCheckout, deleteLocalStorage, writeToLocalStorage } from '@/components/Checkout/utils/CheckoutProcess';
import { identifyUser } from '@/lib/analytics';
import * as Events from '@/analytics/events';

/**
 * Handles the checkout submission process for both parent and teacher flows
 * @param {Object} params - Parameters for the checkout submission
 * @param {Object} params.data - Form data containing user input
 * @param {Object} params.router - Next.js router instance
 * @param {Object} params.analytics - Analytics instance for tracking events
 * @param {Function} params.beginCheckout - API function to initiate checkout
 * @param {Function} params.setLoading - Loading state setter function
 * @param {Function} params.setError - Error state setter function for form validation
 * @param {string} params.id - identifier for tracking purposes
 * @returns {Promise} Promise that resolves after handling checkout flow
 */

// Helper function to handle checkout flow
export const handlePreCheckout = ({
    data,
    router,
    analytics,
    setLoading,
    setError,
    id
}) => {
    // Determine the current page type
    const isParentsPage = router.pathname === '/parents';
    const isTeachersPage = router.pathname === '/teachers';

    // Clear previous checkout state
    deleteLocalStorage('checkout-state');

    // Identify user and track initial email signup
    identifyUser({ email: data.email });
    analytics.identify({ email: data.email });

    const initialEvent = {
        ...router.query,
        ...data,
        sourcePage: router.pathname,
        isParentsPage,
        isTeachersPage
    };

    analytics.track(Events.EmailSignup, initialEvent);

    setLoading(true);

    // Prepare checkout payload
    const payload = {
        email: data.email,
        anonymous_id: analytics.getAnonymousId(),
        logrocket_session_url: analytics.getLogrocketSessionURL(),
        metadata: {
            ...data.metadata,
            anonymous_id: analytics.getAnonymousId(),
            logrocket_session_url: analytics.getLogrocketSessionURL(),
            location: window.location.href,
            'input-id': id,
            'query-string': router.query
        }
    };

    return beginCheckout(payload)
        .then((response) => {
            const { token, email, error, errors } = response;

            // Handle errors if present
            if (error || errors) {
                analytics.track(Events.EmailSignupError, {
                    ...response,
                    isParentsPage,
                    isTeachersPage,
                    beginCheckout: isParentsPage
                });

                if (error) {
                    console.error(error);
                } else if (errors) {
                    for (const [key, value] of Object.entries(errors)) {
                        setError(key, { type: 'manual', message: value.toString() });
                    }
                }
                setLoading(false);
                return;
            }

            // Check if email is likely a teacher email
            const teacherEmail = /\b\.org\b|\b\.net\b|\b\.edu\b/.test(data.email);

            // Determine destination based on current page and email type
            let destPathname = '/onboarding';
            // if (isParentsPage) destPathname = '/checkout';
            if (isTeachersPage) destPathname = '/signup';

            const successEvent = {
                ...router.query,
                ...data,
                email,
                teacherEmail,
                sourcePage: router.pathname,
                isParentsPage,
                isTeachersPage,
                token
            };
            // Track successful email signup
            // analytics.track(Events.EmailSignupSuccessful, successEvent);

            // Handle navigation based on user type
            if (isTeachersPage || teacherEmail) {
                const url = createUrlWithParams('/signup', { ...router.query, email, token });
                return router.push(url, undefined, { shallow: false }).then(() => {
                    analytics.track(Events.NewTeacherRegister, successEvent);
                });
            } else if (isParentsPage) {
                const destPath = createUrlWithParams(destPathname, { ...router.query, email, token });
                return router.push(destPath, undefined, { shallow: false }).then(() => {
                    // TODO: change this to unknown registration event
                    analytics.track(Events.NewParentRegister, successEvent, {}, () => {
                        // console.log('NewParentRegister event tracked');
                    });
                });
            } else {
                // main page - go to onboarding
                const destPath = createUrlWithParams(destPathname, { ...router.query, email, token });
                // return analytics.track(Events.UserTypeSelectionBegins, successEvent, {}, () => {
                // });
                return router.push(destPath, undefined, { shallow: true });
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        });
};
