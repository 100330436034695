import { useEffect, useRef } from 'react';
import Player from '@vimeo/player';

interface VimeoPlayerProps {
    videoId: string;
    autoplay?: boolean;
    onReady?: (player: any) => void;
}

const VimeoPlayer = ({ videoId, autoplay = true, onReady }: VimeoPlayerProps) => {
    const containerRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current && !playerRef.current) {
            playerRef.current = new Player(containerRef.current, {
                id: videoId,
                autoplay,
                muted: true,
                controls: true,
                responsive: true,
                quality: 'auto',
                playsinline: true,
                background: false
            });

            // Call onReady when the player is ready
            playerRef.current.ready().then(() => {
                if (onReady) {
                    onReady(playerRef.current);
                }
            });
        }

        return () => {
            if (playerRef.current) {
                playerRef.current.unload();
                playerRef.current = null;
            }
        };
    }, [videoId, onReady]);

    return <div ref={containerRef} className='w-full aspect-video' />;
};

export default VimeoPlayer;
