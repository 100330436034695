interface TestimonialCardProps {
    quote: string;
    name: string;
    organization: string;
}

export function TestimonialCard({ quote, name, organization }: TestimonialCardProps) {
    return (
        <div className='bg-white rounded-xl py-10 px-8 shadow-lg h-full flex flex-col'>
            <div>
                <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M64.3333 20L56.6667 33.3333C60.3333 33.3333 63.4722 34.6389 66.0833 37.25C68.6944 39.8611 70 43 70 46.6667C70 50.3333 68.6944 53.4722 66.0833 56.0833C63.4722 58.6944 60.3333 60 56.6667 60C53 60 49.8611 58.6944 47.25 56.0833C44.6389 53.4722 43.3333 50.3333 43.3333 46.6667C43.3333 45.3889 43.4856 44.2078 43.79 43.1233C44.0944 42.0389 44.5533 40.9978 45.1667 40L56.6667 20L64.3333 20ZM34.3333 20L26.6667 33.3333C30.3333 33.3333 33.4722 34.6389 36.0833 37.25C38.6944 39.8611 40 43 40 46.6667C40 50.3333 38.6944 53.4722 36.0833 56.0833C33.4722 58.6944 30.3333 60 26.6667 60C23 60 19.8611 58.6944 17.25 56.0833C14.6389 53.4722 13.3333 50.3333 13.3333 46.6667C13.3333 45.3889 13.4856 44.2078 13.79 43.1233C14.0944 42.0389 14.5533 40.9978 15.1667 40L26.6667 20L34.3333 20Z'
                        fill='#5A41B4'
                    />
                </svg>
            </div>
            <p className='text-[#1c0057] text-xl font-medium leading-[24px] sm:leading-[30px] flex-grow mb-4 my-0'>{quote}</p>
            <div className='text-[#1C0057] text-base my-0'>
                - {name} {organization}
            </div>
        </div>
    );
}
