import axios from 'axios';
import { deleteLocalStorage } from '@/components/Checkout/utils/CheckoutProcess';
import { CheckoutError } from '@/analytics/events';
import { MUZOLOGY_API, MUZOLOGY_APP } from '@/lib/defaults';
import { handleRedirection } from '@/lib/redirect';

/**
 * parent signup handler for free 7 day trial
 * @param {Object} params - Parameters for the signup
 * @param {Object} params.data - Form data
 * @param {Object} params.analytics - Analytics instance
 * @param {Function} params.setLoading - Loading state setter
 * @param {Function} params.setError - Form error setter
 */

export const submitParentFreeTrial = async ({
    data,
    analytics,
    setLoading,
    setError,
    setRedirectModal,
    setInactiveAccount,
    router
}) => {
    const payload = {
        /* eslint-disable camelcase */
        ...data,
        anonymous_id: analytics.getAnonymousId()
        /* eslint-enable camelcase */
    };

    // Identify user
    analytics.identify({
        ...payload,
        userType: 'parent'
    });

    setLoading(true);

    axios
        .post('/api/signup/parent/', payload)
        .then((res) => {
            console.log('res', res.data);
            const { clientSecret, token, error, errors } = res.data;
            if (error || errors) {
                console.error('error', error);
                console.error('errors', errors);
                analytics.track(CheckoutError, { error, errors, ...router.query });
                setLoading(false);
                return;
            }

            deleteLocalStorage('checkout-state');

            // redirect to the front end for onboarding
            handleRedirection(res);

            // track the event
            // analytics.track(NewParentAccount, { ...data, response: res.data }, {}, () => {
            // });
        })
        .catch((err) => {
            setLoading(false);
            console.log('checkout begin error:', err);
            if (err.response?.status === 400 && typeof err.response?.data === 'object') {
                // validation error -> set form errors
                for (let [key, value] of Object.entries(err.response.data)) {
                    console.log(key, ':', value.toString(), value);
                    // transform the error message
                    // if this is an array with a single element, then just use that element
                    if (value.toString() === 'This field must be unique.') {
                        value = 'This username is already taken';
                    }
                    if (key === 'email') {

                        if (value.toString() === 'An account with this email already exists. This account has not been created yet.') {
                            console.log('redirecting to onboarding to continue setup');
                            setLoading(true);
                            // redirect to the front end for onboarding
                            setTimeout(() => {
                                const url = `${MUZOLOGY_API}/onboard/setup/?email=${encodeURIComponent(data.email)}`;
                                console.log('redirecting to onboarding to continue setup:', url);
                                window.location.href = url;
                            }, 100);
                            return;
                        }

                        if (value.toString() === 'An account with this email already exists.') {
                            setRedirectModal(true);
                            setTimeout(() => {
                                window.location.href = MUZOLOGY_APP;
                            }, 5000);
                        }

                        if (value.toString() === 'An inactive account with this username already exists. User can re-activate the account.') {
                            setInactiveAccount(true);
                            setRedirectModal(true);
                            setTimeout(() => {
                                window.location.href = MUZOLOGY_APP;
                            }, 5000);
                        }
                    } else {
                        setError(key as any, { type: 'manual', message: value.toString() });
                    }
                }
            } else {
                console.error(err);
            }
        });
};
