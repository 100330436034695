import { Button } from '@/components/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/components/ui/card';
import { useRouter } from 'next/router';
import { MUZOLOGY_SIGNUP_URL } from '@/lib/defaults';

export default function PricingSection({ hideBlur = false }) {
    const router = useRouter();

    const handleStartFreeTrial = () => {
        router.push(MUZOLOGY_SIGNUP_URL);
    };

    return (
        <div className='relative w-full px-4 md:px-10 lg:px-12 lx:px-[70px] py-10 sm:py-12 text-white'>
            {/* blur effect pink */}
            {!hideBlur && (
                <div className='hidden md:block absolute top-[60px] left-0 rounded-full opacity-[1.4] bg-[#B031FA] bg-opacity-[0.7] blur-[250px] w-[729px] h-[585px] z-[1]' />
            )}
            {/* blur effect dark purple */}
            {!hideBlur && (
                <div className='hidden md:block absolute top-[-70px] right-0 rounded-full opacity-[1.4] bg-[#161042] bg-opacity-[0.7] blur-[250px] w-[729px] h-[585px] z-[1]' />
            )}
            <div className='max-w-[1171px] mx-auto space-y-8 relative z-[2] mt-10'>
                {/* Header */}
                <div className='text-center z-[2]'>
                    <h1 className=' text-[50px] xl:text-[60px] leading-[50px] xl:leading-[60px] font-normal my-0 hidden lg:block max-h-[110px] mb-2'>
                        Math that <span className='text-[#FFDB35] font-Gloria'>sticks.</span> Music that{' '}
                        <span className='text-[#64DFE5] font-Gloria'>rocks.</span>
                    </h1>
                    <div className='block lg:hidden'>
                        <h1 className='text-[40px] font-normal md:text-[50px] my-0'>
                            Math that <span className='text-[#FFDB35] font-Gloria'>sticks.</span>
                        </h1>
                        <h1 className='text-[40px] font-normal md:text-[50px] my-0 leading-4'>
                            Music that <span className='text-[#64DFE5] font-Gloria'>rocks.</span>
                        </h1>
                    </div>
                    <div className='text-xl font-normal hidden lg:block'>
                        <p className='my-0 md:leading-[1.85rem]'>Start with our All-Access "Math Success Plan" covering critical 3rd-8th grade math topics.</p>
                        <p className='my-0 md:leading-[1.85rem]'>Or focus on multiplication with our "Times Tables" Mini-Pack.</p>
                    </div>
                    <p className='my-0 leading-0 block lg:hidden text-base md:text-lg  mt-5 md:mt-10 text-center'>
                        Start with our All-Access "Math Success Plan". Or focus on multiplication with our "Times Tables" Mini-Pack.
                    </p>
                </div>

                {/* Pricing Cards */}
                <div className='flex flex-col md:flex-row gap-9 lg:gap-12 justify-center'>
                    {/* All-Access Card */}
                    <Card
                        className='relative overflow-hidden bg-pricing-background-all-access bg-cover bg-no-repeat rounded-3xl md:rounded-xl text-black w-full h-full max-w-full md:max-w-[369px] py-0 sm:py-5 '>
                        <CardHeader className=' p-0 sm:p-6 pt-5 sm:pt-6 pb-2 sm:pb-6'>
                            <CardTitle className='text-[28px] sm:text-[42px] leading-8 sm:leading-[46px] font-semibold text-center'>
                                All-Access
                                <br />
                                Math Success{' '}
                                <br className='hidden sm:block' />
                                Plan
                            </CardTitle>
                        </CardHeader>
                        <CardContent className='px-8 md:px-6 pb-4 xl:pb-7 pt-0'>
                            <div className='text-center font-medium text-[24px]'>
                                TRY IT FREE
                            </div>
                            <div className='pl-[10px] sm:ml-0'>
                                <div className='hidden lg:block text-base font-normal max-w-[290px] mx-auto mt-3'>
                                    • Music videos for critical math topics
                                </div>
                                <div className='hidden lg:block text-base font-normal sm:text-center'>
                                    (Grades 3-8)
                                </div>

                                <div
                                    className='block lg:hidden text-base font-normal max-w-full sm:max-w-[348px] lg:max-w-[290px] mx-auto mt-3 text-left sm:text-center'
                                >
                                    • Music videos for critical math topics (Grades 3-8)
                                </div>

                                <div className='text-base font-normal mt-1.5 max-w-full lg:max-w-[258px] mx-auto opacity-[.8] text-left sm:text-center'>
                                    • <span className='font-bold'>70 lessons</span> and <span className='font-bold'>over 2,000 quiz questions</span>
                                </div>
                                <div className='text-base font-normal mt-1.5 text-left sm:text-center max-w-full sm:max-w-[370px] md:max-w-[300px] mx-auto'>
                                    • Flexible monthly or annual plans available after free trial.
                                </div>

                            </div>
                        </CardContent>

                        <div className='flex justify-center  pb-5 sm:pb-0'>
                            <Button onClick={handleStartFreeTrial}
                                className='font-semibold rounded-[50px] text-lg px-6 sm:px-10 py-7 sm:py-7 border-none bg-[#F11581] text-white transition-all duration-300 ease-in-out hover:bg-[#C2006E]'>
                                Start Free Trial
                            </Button>
                        </div>
                    </Card>

                    {/* Times Tables Card */}
                    <Card
                        className='relative overflow-hidden bg-pricing-background-mini-pack bg-cover bg-no-repeat rounded-3xl md:rounded-xl text-black w-full  max-w-full md:max-w-[369px] pb-0 sm:pb-5'>
                        <div>
                            <div className='flex justify-center mt-6 md:mt-8'>
                                <div className='bg-white/80 px-6 py-4 rounded-full text-[16px] text-[#1C0057]'>Mini-Pack</div>
                            </div>
                            <CardTitle className='text-[28px] sm:text-[42px] font-semibold text-center mt-2 md:mt-6 leading-[54px]'>
                                Times Tables</CardTitle>
                        </div>
                        <div className='md:mt-[2rem] px-8 sm:px-0 sm:pt-2'>
                            <div className='text-center font-medium text-[24px]'>
                                ONLY $9.99
                            </div>
                            <div className='ml-[10px] sm:ml-0'>
                                <div className='text-base font-normal mt-3 sm:max-w-[270px] sm:mx-auto'>
                                    • Perfect for times tables learning
                                </div>
                                <div className='text-base font-normal opacity-[.8] mt-2 sm:max-w-[300px] text-left  sm:text-center sm:mx-auto'>
                                    • <span className='font-bold'>11 lessons</span> and <span className='font-bold'>over 300 quiz questions</span>
                                </div>
                                <div className='text-base font-normal opacity-[.8] mt-2 sm:max-w-[300px] text-left  sm:text-center sm:mx-auto'>
                                    • 99 days of access
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center items-end pb-5 md:pb-0 mt-4 md:-mt-2 mb-auto sm:h-[29%] '>
                            <Button
                                onClick={() => {
                                    router.push('/timestables');
                                }}
                                className='font-semibold rounded-[50px] text-lg px-10 py-7 border-none bg-[#6E60E6] text-white transition-all duration-300 ease-in-out hover:bg-[#5E4BBF]'>
                                Learn More
                            </Button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}
