import React, { useEffect, useRef, useState } from 'react';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { Layout } from 'components/Layout';
import { getPageStaticProps } from 'lib/dato-request';
import { useRouter } from 'next/router';
import StickyFooterForm from '@/components/StickyFooterForm/StickyFooterForm';
import IntersectionObserverElement from '@/components/IntersectionObserverElement/IntersectionObserverElement';
import { useAnalytics } from '@/analytics/AnalyticsProvider';
import { useMedia } from 'react-use';
import { Footer } from 'components/Footer/NewFooter2';
import RedirectModal from '@/components/RedirectModal/RedirectModal';
import TimesTableFAQ from '@/components/TimesTables/FAQ/TimesTableFAQ';
import { GirlCtaSection, VideoSection, ChartSection, PricingSection, TestimonialsSection, VideoSectionFlex, HeroVideoPlayer, AwardsSection } from '@/components/HomePageV2';

function getCookies() {
    if (typeof window === 'undefined') {
        return [];
    }
    const cookies = {};
    window.document.cookie.split(';').forEach((cookie) => {
        const parts = cookie.split('=');
        cookies[parts[0].trim()] = (parts[1] || '').trim();
    });
    return cookies;
}

function getCookie(name) {
    return getCookies()[name];
}

const NewHome = ({ data, location = 'home' }) => {
    const analytics = useAnalytics();
    const router = useRouter();
    const { page, site, videoGroups } = data;
    const { components, seo } = page;
    console.log('🚀 ~ NewHome ~ components:', components);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const hideFooter = useMedia('(max-width: 445px)', false);
    const [redirectModal, setRedirectModal] = useState(null);
    const [inactiveAccount, setInactiveAccount] = useState(false);
    const { infoItems } = components[6];
    const info1 = infoItems[0];
    const info2 = infoItems[1];
    const info3 = infoItems[2];
    const [topElementIsVisible, updateElementIsVisible] = useState<any | null>(null);



    return (
        <Layout hideFooter={hideFooter} >
            <NextSeo title={seo?.title} description={seo?.description} />
            <Head>
                <meta name='keywords' content={page.keywords} />
            </Head>

            <div className='bg-home-background w-full min-h-[100vh] font-Lexend relative z-0'>
                <HeroVideoPlayer />
                <PricingSection />
                <IntersectionObserverElement updateElementIsVisible={updateElementIsVisible} classNames='absolute top-[19rem]' ref={elementRef} />
                <VideoSection/>
                <GirlCtaSection />
                <ChartSection />
                <div className='bg-[#1F0833] bg-opacity-40 relative z-0'>
                    <VideoSectionFlex />
                    <AwardsSection awardsImages={components[9].gallery}/>
                    <TestimonialsSection testimonials={components[11].testimonials}/>
                </div>
                <div className='mt-[120px]'>
                    <TimesTableFAQ faqs={components[10].faqQuestion} homePage={true} />
                </div>
                <div className='bg-[#1F0833] bg-opacity-40 pb-10'>
                    <PricingSection hideBlur={true}/>
                </div>
            </div>
            <div className='block xs2:hidden mb-20 -mt-10'>
                <Footer hideCTA={true} />
            </div>
            <StickyFooterForm topElementIsVisible={topElementIsVisible} id='mobile-sticky-email-capture' setRedirectModal={setRedirectModal} setInactiveAccount={setInactiveAccount}/>
            {redirectModal && <RedirectModal reActivate={inactiveAccount}/>}

        </Layout>
    );
};

export async function getStaticProps({ preview = false }) {
    return getPageStaticProps('Homepage New');
}

export default NewHome;
