import React, { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { Input } from '../../components/ui/input';
import { Button } from '../Components/Button';
import { MUZOLOGY_APP } from '@/lib/defaults';
import { beginCheckout } from '@/components/Checkout/utils/CheckoutProcess';
import { handlePreCheckout } from '@/lib/checkout_helpers/handlePreCheckout';
import { submitParentFreeTrial } from '@/lib/checkout_helpers/submitParentFreeTrail';
import { useAnalytics } from '@/analytics/AnalyticsProvider';
import { useForm } from 'react-hook-form';
import { checkValidEmail } from '@/lib/muzology';

const EmailSignupField = ({ id = 'email-signup', className = null, inputClassName = null }) => {
    const [email, setEmail] = useState('');
    const router = useRouter();
    const isParentsPage = router.pathname === '/parents';
    const isTeachersPage = router.pathname === '/teachers';
    const analytics = useAnalytics();
    const timestamp = Date.now();
    const [isLoading, setLoading] = React.useState(false);
    const defaultValues = {
        email: null,
        // timestamp: timestamp
    };
    // if (process.env.NEXT_PUBLIC_FILL_TEST_DATA) {
    // defaultValues.email = `test-${timestamp}@muzology.com`;
    // }
    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors }
    } = useForm({ defaultValues });
    const setRedirectModal = null;
    const setInactiveAccount = null;
    // const handleSubmit = (e) => {
    //     if (isParentsPage) {
    //         window.location.href = `${MUZOLOGY_API}/signup?email=${encodeURIComponent(data.email)}&userType=parent`;
    //     } else {
    //         window.location.href = `${MUZOLOGY_API}/signup?email=${encodeURIComponent(data.email)}`;
    //     }
    //
    //     e.preventDefault();
    //     // if (email) {
    //     //     const encodedEmail = encodeURIComponent(email);
    //     //     router.push(`${MUZOLOGY_APP}/signup?email=${encodedEmail}`);
    //     // }
    // };

    const onSubmitBeginCheckout = useCallback(
        (data) => {
            const config = {
                data,
                router,
                analytics,
                beginCheckout,
                setLoading,
                setError,
                id
            };
            return handlePreCheckout(config);
        },
        [analytics, id, router, setError]
    );

    const onSubmitParentFreeTrial = useCallback(
        (data) => {
            const config = {
                data,
                analytics,
                setLoading,
                setError,
                setRedirectModal,
                setInactiveAccount,
                router
            };
            return submitParentFreeTrial(config);
        },
        [analytics, setError, setRedirectModal, setInactiveAccount, router]
    );

    const onSubmit = useCallback(
        (data) => {
            // return isParentsPage ? onSubmitParentFreeTrial(data) : onSubmitBeginCheckout(data);
            return onSubmitBeginCheckout(data);
            // return onSubmitBeginCheckout(data);
            // if (isParentsPage) {
            //     window.location.href = `${MUZOLOGY_APP}/signup?email=${encodeURIComponent(data.email)}&userType=parent`;
            // } else {
            //     window.location.href = `${MUZOLOGY_APP}/signup?email=${encodeURIComponent(data.email)}`;
            // }
        },
        [isParentsPage, onSubmitBeginCheckout, onSubmitParentFreeTrial]
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={className}>
            <Input
                type='email'
                placeholder='Enter Your Email'
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
                className={inputClassName ?? 'max-w-[260px] md:max-w-[300px] pl-0 md:pl-4 py-[26px] border-[1px] border-solid border-white text-white font-normal rounded-full text-center md:text-left font-Lexend text-lg'}
                style={{ background: 'rgba(255, 255, 255, 0.15)' }}
                // required
                {...register('email', { required: true, validate: { validEmail: checkValidEmail } })}
            />
            <div className='w-full max-w-[260px] md:max-w-[180px]'>
                <Button type='submit' variant='primary' className='w-full md:max-w-[180px] px-6 py-4 text-lg'>
                    Start Free Trial
                </Button>
            </div>
        </form>
    );
};

export default EmailSignupField;

