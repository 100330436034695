import { useState, useRef, useEffect } from 'react';
import VimeoPlayer from '@/components/VimeoPlayer/VimeoPlayer';
import IntersectionObserverElement from '@/components/IntersectionObserverElement/IntersectionObserverElement';

export default function VideoSection() {
    const [isPlaying, setIsPlaying] = useState(false);
    const [sectionVisible, setSectionVisible] = useState<any | null>(null);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const playerRef = useRef<any>(null);

    useEffect(() => {
        // When the section becomes visible, start a 3-second timer
        if (sectionVisible) {
            const timer = setTimeout(() => {
                if (playerRef.current) {
                    playerRef.current.play();
                }
            }, 3000);

            return () => clearTimeout(timer);
        }
        if (!sectionVisible && playerRef.current) {
            playerRef.current.pause();
        }
    }, [sectionVisible]);

    return (
        <div className='max-w-[1300px] mx-auto px-4 md:px-10 lg:px-12 lx:px-[70px] sm:pt-4'>
            <IntersectionObserverElement updateElementIsVisible={setSectionVisible} classNames='' ref={elementRef} />
            <div className='text-center mt-8 sm:mt-10'>
                <h3 className='text-white font-normal text-[40px] lg:text-[50px] xl:text-[60px] my-0 leading-[3rem] md:leading-[50px] xl:leading-[60px] mb-2'>
                    Backed by <span className='font-Gloria text-[#E0C8F9]'>science.</span> Packed with <span className='font-Gloria text-[#64DFE5]'>fun.</span>
                </h3>
                <p className='text-white text-base sm:text-[20px] font-medium my-0 mt-1.5 sm:mt-0 max-w-[1062px] mx-auto leading-6 sm:leading-[30px]'>
                Music activates brain regions for successful learning. Muzology's scientifically-designed music videos improve grades, build confidence and make math fun.
                </p>
            </div>
            <div className='ghost-inspector-hide mt-5 sm:mt-10 max-w-[1000px] mx-auto'>
                <VimeoPlayer
                    videoId='1051215207'
                    onReady={(player) => {
                        playerRef.current = player;
                    }}
                />
            </div>
        </div>
    );
}
