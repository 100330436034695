import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { checkValidEmail } from '@/lib/muzology';
import { beginCheckout, deleteLocalStorage, writeToLocalStorage } from '@/components/Checkout/utils/CheckoutProcess';
import { useAnalytics } from '@/analytics/AnalyticsProvider';
import cx from 'classnames';
import { createUrlWithParams } from '@/lib/urls';
import { identifyUser } from '@/lib/analytics';
import * as Events from '@/analytics/events';
import { submitParentFreeTrial } from '@/lib/checkout_helpers/submitParentFreeTrail';
import { handlePreCheckout } from '@/lib/checkout_helpers/handlePreCheckout';
import { MUZOLOGY_APP } from '@/lib/defaults';

const StickyFooterForm = ({ topElementIsVisible, setRedirectModal, setInactiveAccount, id }) => {
    const router = useRouter();
    const analytics = useAnalytics();

    const { testPrefill = null } = router.query ?? {};
    const timestamp = Date.now();
    const defaultValues = {
        email: null,
        metadata: {
            timestamp
        }
    };
    const [isLoading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors }
    } = useForm({ defaultValues });

    const onSubmitBeginCheckout = useCallback(
        (data) => {
            const config = {
                data,
                router,
                analytics,
                beginCheckout,
                setLoading,
                setError,
                id
            };
            return handlePreCheckout(config);
        },
        [analytics, id, router, setError]
    );

    const onSubmitParentFreeTrial = useCallback(
        (data) => {
            const config = {
                data,
                analytics,
                setLoading,
                setError,
                setRedirectModal,
                setInactiveAccount,
                router
            };
            return submitParentFreeTrial(config);
        },
        [analytics, setError, setRedirectModal, router]
    );

    const isParentsPage = router.pathname === '/parents';
    // const onSubmit = useCallback(
    //     (data) => {
    //         return isParentsPage ? onSubmitParentFreeTrial(data) : onSubmitBeginCheckout(data);
    //     },
    //     [isParentsPage, onSubmitParentFreeTrial, onSubmitBeginCheckout]
    // );

    const onSubmit = (data) => {
        if (data.email) {
            const encodedEmail = encodeURIComponent(data.email);
            router.push(`${MUZOLOGY_APP}/signup?email=${encodedEmail}`);
        }
    };

    // snag the onBlur event
    const props = register('email', { required: true, validate: { validEmail: checkValidEmail } });

    // pre-fetch the possible destination page
    // useEffect(() => {
    //     if (router.pathname === '/parents') {
    //         router.prefetch('/checkout');
    //     } else {
    //         router.prefetch('/onboarding');
    //     }
    // }, [router]);

    return (
        <div
            id='footer-email-signup'
            className={cx(
            `bg-[#271556]      
            xs2:hidden
            text-3xl text-white text-center
            border-t-4
            fixed
            inset-x-0
            bottom-0
            py-4 px-5 z-50
            transition-opacity ease-in duration-500`,
                {
                    'opacity-0': topElementIsVisible
                }
            )}
        >
            <div
                className={cx('hidden fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 justify-center items-center hidden', {
                    flex: isLoading
                })}
            ></div>

            <form onSubmit={handleSubmit(onSubmit)} className='flex w-full'>
                <div className='w-full'>
                    <input
                        {...props}
                        placeholder='Your Email'
                        className='text-left bg-white placeholder-current w-[100%] lg:w-[100%] h-[54px] px-4 py-5 rounded-tl-xl rounded-bl-xl rounded-tr-none rounded-br-none justify-between items-center inline-flex text-base font-normal font-Poppins leading-loose tracking-wide'
                    />
                    {errors.email && <div className='text-red-500 mt-1 text-sm md:text-lg text-center'>Please enter valid email</div>}
                </div>
                <button className='whitespace-nowrap w-[144px] h-[54px] px-8 py-5 bg-[#FFD60A] rounded-tr-xl rounded-br-xl border-none justify-center items-center gap-4 inline-flex '>
                    <div className='text-[#000] text-center text-base font-semibold font-Poppins leading-loose tracking-wide flex items-center justify-center'>
                        <div>Try it FREE</div>
                        <div className='flex justify-center mt-[-2px]'>
                            <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M18.9081 12.8861C18.8589 12.7592 18.7851 12.6433 18.691 12.545L13.5227 7.37668C13.4264 7.28031 13.3119 7.20386 13.186 7.1517C13.0601 7.09954 12.9251 7.07269 12.7888 7.07269C12.5136 7.07269 12.2496 7.18204 12.0549 7.37668C11.9586 7.47306 11.8821 7.58747 11.83 7.7134C11.7778 7.83932 11.751 7.97428 11.751 8.11058C11.751 8.38584 11.8603 8.64983 12.0549 8.84447L15.466 12.2452H7.62057C7.34643 12.2452 7.08351 12.3541 6.88966 12.5479C6.69582 12.7418 6.58691 13.0047 6.58691 13.2788C6.58691 13.553 6.69582 13.8159 6.88966 14.0097C7.08351 14.2036 7.34643 14.3125 7.62057 14.3125H15.466L12.0549 17.7132C11.9581 17.8093 11.8812 17.9236 11.8287 18.0496C11.7762 18.1756 11.7492 18.3107 11.7492 18.4471C11.7492 18.5836 11.7762 18.7187 11.8287 18.8446C11.8812 18.9706 11.9581 19.0849 12.0549 19.181C12.151 19.2779 12.2654 19.3548 12.3913 19.4073C12.5173 19.4597 12.6524 19.4868 12.7888 19.4868C12.9253 19.4868 13.0604 19.4597 13.1864 19.4073C13.3123 19.3548 13.4266 19.2779 13.5227 19.181L18.691 14.0127C18.7851 13.9144 18.8589 13.7985 18.9081 13.6716C19.0115 13.42 19.0115 13.1377 18.9081 12.8861Z'
                                    fill='black'
                                />
                            </svg>
                        </div>
                    </div>
                </button>
            </form>
        </div>
    );
};

export default StickyFooterForm;
