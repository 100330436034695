export default function RocketSvg() {
    return (
        <svg width='170' height='219' viewBox='0 0 170 219' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M28.9454 161.952L32.0598 115.236L85.0048 68.5199L137.95 115.236L141.064 161.952C141.064 161.952 122.384 143.266 85.0048 143.266C47.6256 143.266 28.9454 161.952 28.9454 161.952Z'
                fill='url(#paint0_linear_1272_59696)'
            />
            <path
                d='M84.9117 218.096C84.9117 218.096 59.9497 193.358 59.9497 174.541C59.9497 155.724 85.5782 131.391 85.5782 131.391C85.5782 131.391 109.908 155.727 109.908 174.413C109.908 193.1 84.9117 218.096 84.9117 218.096Z'
                fill='#FFD100'
            />
            <path
                d='M84.9969 0.000196028C84.9969 0.000196028 53.8528 31.1443 47.6239 99.6613C44.5095 133.92 72.5392 161.95 84.9969 161.95C97.4545 161.95 125.484 133.92 122.37 99.6613C116.141 31.1443 84.9969 0.000196028 84.9969 0.000196028Z'
                fill='#1CC8E1'
            />
            <circle cx='84.9977' cy='54.9996' r='15.4' fill='url(#paint1_linear_1272_59696)' />
            <path
                d='M84.9969 174.41C84.9969 174.41 72.5392 161.952 72.5392 155.723C72.5392 149.495 78.7743 80.9776 84.9969 80.9776C91.2195 80.9776 97.4514 149.498 97.4514 155.727C97.4514 161.955 84.9969 174.41 84.9969 174.41Z'
                fill='url(#paint2_linear_1272_59696)'
            />
            <defs>
                <linearGradient id='paint0_linear_1272_59696' x1='58.3653' y1='116.717' x2='150.187' y2='197.491' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#6E60E6' />
                    <stop offset='1' stopColor='#1CC8E1' />
                </linearGradient>
                <linearGradient id='paint1_linear_1272_59696' x1='84.3637' y1='45.0955' x2='86.5489' y2='79.2333' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#6E60E6' />
                    <stop offset='1' stopColor='#1CC8E1' />
                </linearGradient>
                <linearGradient id='paint2_linear_1272_59696' x1='68.9385' y1='113.349' x2='124.289' y2='162.04' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#6E60E6' />
                    <stop offset='1' stopColor='#1CC8E1' />
                </linearGradient>
            </defs>
        </svg>
    );
}
