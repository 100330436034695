import Image from 'next/image';

export default function AwardsSection({ awardsImages }) {
    const slicedImages = awardsImages.slice(0, 7);
    return (
        <div className='max-w-[1300px] mx-auto px-4 py-8 lg:py-8 relative z-[1]'>
            <div className='hidden lg:grid grid-cols-4 lg:grid-cols-7 md:gap-14 md:items-center md:justify-items-center'>
                {slicedImages.map((awardImage, index) => (
                    <div key={index} className='w-full max-w-[200px] h-[100px] relative'>
                        <Image 
                            src={awardImage.url} 
                            alt='Award' 
                            layout='fill' 
                            className='object-contain'
                            priority={true}
                        />
                    </div>
                ))}
            </div>
            <div className='lg:hidden'>
                <div className='grid grid-cols-4 gap-2.5 items-center justify-items-center mb-4 md:mb-7'>
                    {slicedImages.slice(0, 4).map((awardImage, index) => (
                        <div key={index} className='w-full max-w-[100px] md:max-w-[200px] h-[60px] md:h-[100px] relative'>
                            <Image 
                                src={awardImage.url} 
                                alt='Award' 
                                layout='fill' 
                                className='object-contain'
                                priority={true}
                            />
                        </div>
                    ))}
                </div>
                <div className='grid grid-cols-3 gap-2.5 items-center justify-items-center'>
                    {slicedImages.slice(4, 7).map((awardImage, index) => (
                        <div key={index} className='w-full max-w-[100px] md:max-w-[200px] h-[60px] md:h-[100px] relative'>
                            <Image 
                                src={awardImage.url} 
                                alt='Award' 
                                layout='fill' 
                                className='object-contain'
                                priority={true}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
